@import '_variables.scss';
@import '(bootstrap).scss';
@import 'margin_classes.scss';

@import 'global.scss';

@import 'subnav.scss';
@import 'carousel.scss';
@import 'page_accueil.scss';
/* @import 'page_metier.scss';
@import 'page_prestations.scss'; */
@import 'footer.scss';
@import 'tarteaucitron.scss';
