#footer {
	background-color: darken($color_background, 10%);
	padding: $padding_top_footer 0 $padding_bottom_footer 0;
}

.logobas {
	height: 80px;
	background: url('../images/logo_abf_1.png') no-repeat center right;
	background-size: contain;
	margin-top: 13px;
}

.footer_adresse {
	color: $footer_txtcolor;
	font-size: $footer_txtsize;
}

.footer_links {
	font-size: $footer_txtsize;
	list-style-type: none;
	border-left: 2px solid white;
	padding-left: 10px;
}

.footer_li {
	color: $footer_linkscolor !important;
}

.footer_li:hover {
	color: $color2 !important;
}

.legals_container {
	width: 100%;
	margin-top: $footer_legals_margintop;
	text-align: center;
	color: $footer_txtcolor;
	font-weight: 600;
}

#legals {
	cursor: pointer;
}

#legal-mentions {
	display: none;
}

.popover {
	max-width: 80vw;
}

.popover-content {
	background-color: darken($color2, 20%);
}