.mt0 { margin-top: 0px; } 
.mt10 { margin-top: 10px; }
.mt20 { margin-top: 20px; }
.mt30 { margin-top: 30px; }
.mt40 { margin-top: 40px; }
.mr0 { margin-right: 0px; } 
.mr10 { margin-right: 10px; }
.mr20 { margin-right: 20px; }
.mr30 { margin-right: 30px; }
.mr40 { margin-right: 40px; }
.ml0 { margin-left: 0px; } 
.ml10 { margin-left: 10px; }
.ml20 { margin-left: 20px; }
.ml30 { margin-left: 30px; }
.ml40 { margin-left: 40px; }
.mb0 { margin-bottom: 0px; }
.mb10 { margin-bottom: 10px; }
.mb20 { margin-bottom: 20px; }
.mb30 { margin-bottom: 30px; }
.mb40 { margin-bottom: 40px; }
.p0 { padding-bottom: 0px; }
.p10 { padding-bottom: 10px; }
.p20 { padding-bottom: 20px; }
.p30 { padding-bottom: 30px; }
.p40 { padding-bottom: 40px; }