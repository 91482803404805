// Global
$color_background: #404040;
$color2: #e81b22;

$textbox_background_color: #ffffff;
$textbox_border_color: #999999;
$textbox_border_size: 0px;
$textbox_text_color: #404040;

$spacer1_height: 40px;

$submenu_background_color: rgba(0, 0, 0, 0.5);
$submenu_text_color: white;
$submenu_paddings: 0 30px 0 30px;
$linkactive_color: $color2;

$h1_line_height: 1.2em;


// Page 1 : Accueil
$carousel_height: 90vh;

$url_cssimage_1: '../images/accueil_imgmiddle.jpg';
$cssimage_1_height: 60vh;
$cssimage_1_margintop: 40px;

$googlemap_height: 60vh;


// Images haut pages
$imgtop_height: 50vh;
$imgtop_margintop: 0px;

$url_imgtop_entreprise: '../images/entreprise_imgtop.jpg';
$url_imgtop_competences_fabrication: '../images/competences_fabrication_imgtop.jpg';
$url_imgtop_competences_affutage: '../images/competences_affutage_imgtop.jpg';
$url_imgtop_competences_generales: '../images/competences_generales_imgtop.jpg';
$url_imgtop_prestations: '../images/prestations_imgtop.jpg';


// Footer
$padding_top_footer: 50px;
$padding_bottom_footer: 10px;
$footer_txtcolor: #ffffff;
$footer_linkscolor: #ffffff;
$footer_txtsize: 1.1em;
$footer_legals_margintop: 40px;