body {
	background-color: $color_background;
	padding-top: $navbar-height !important;
	padding-bottom: 0px !important;
}

h1 {
	font-weight: 700;
}

h3 {
	margin-bottom: 20px;
}

p {
	font-size: 1.2em;
}

p a { 
	text-decoration: underline;
}

a { color: white; }
a:hover { color: white; }

.linkactive {
	color: $linkactive_color;
}

.container_80p {
	width: 80%;
	margin: auto;
}

#background_container_1 {
	background: url('../images/hachures_background.png') no-repeat center bottom;
    z-index: -10;
}

.textbox {
	border: $textbox_border_size solid $textbox_border_color;
	color: $textbox_text_color;
	padding: 20px;
}

.textbox_background h1 {
	font-weight: 900;
}

.textbox_background {
	background-color: $textbox_background_color;
	padding: 20px;
}

.texte_free {
	margin-bottom: 40px;
	color: white;
}

.texte_free h2 {
	color: white;
}

.titre_underline {
	margin-bottom: 20px;
	line-height: $h1_line_height;
	border-bottom: 1px solid $color2;
}

.image_block {
	margin-top: 30px;
	width: 100%;
}

.spacer1 {
	width: 100%;
	height: $spacer1_height;
}

.txt_white {
	color: #ffffff !important;
}

.grecaptcha-badge {
	visibility: hidden !important;
}


// IMAGES TÊTES PAGES

.imgtop_entreprise {
	height: $imgtop_height;
	margin-top: $imgtop_margintop;
	background: url($url_imgtop_entreprise) no-repeat center center;;
    background-size: cover;
}

.imgtop_competences_fabrication {
	height: $imgtop_height;
	margin-top: $imgtop_margintop;
	background: url($url_imgtop_competences_fabrication) no-repeat center center;;
    background-size: cover;
}

.imgtop_competences_affutage {
	height: $imgtop_height;
	margin-top: $imgtop_margintop;
	background: url($url_imgtop_competences_affutage) no-repeat center center;;
    background-size: cover;
}

.imgtop_competences_generales {
	height: $imgtop_height;
	margin-top: $imgtop_margintop;
	background: url($url_imgtop_competences_generales) no-repeat center center;;
    background-size: cover;
}

.imgtop_prestations {
	height: $imgtop_height;
	margin-top: $imgtop_margintop;
	background: url($url_imgtop_prestations) no-repeat center center;;
    background-size: cover;
}