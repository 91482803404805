$btn-border-radius-base: 0;
$btn-border-radius-large: 0;
$btn-border-radius-small: 0;

$brand-info: #e81b22;

$navbar-inverse-link-active-bg: darken(#e81b22, 10%);
$navbar-inverse-border: #e81b22;

$navbar-margin-bottom: 0 !important;

$popover-max-width: 75%;