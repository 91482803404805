.dropdown-menu a {
  padding: $submenu_paddings;
  text-align: center;
}

.nav_separator_right {
  border-right: 1px solid white;
}

@media (max-width: 768px) {
  .nav_separator_right {
    border-right: none;
  }

  .dropdown-menu a {
    display: block;
    text-align: left;
  }
}

.inactive_link {
  padding: 10px 15px;
}

.competences_tab {
  padding-left: 24px;
}



.mega-dropdown {
  position: static !important;
}
.mega-dropdown-menu {
    padding: 20px 0px;
    width: 100%;
    box-shadow: none;
    -webkit-box-shadow: none;
    background-color: $submenu_background_color;
    color: $submenu_text_color;
}
.mega-dropdown-menu > li > ul {
  padding: 0;
  margin: 0;
}
.mega-dropdown-menu > li > ul > li {
  list-style: none;
}
.mega-dropdown-menu > li > ul > li > a {
  display: block;
  color: #222;
  padding: 3px 5px;
}
.mega-dropdown-menu > li ul > li > a:hover,
.mega-dropdown-menu > li ul > li > a:focus {
  text-decoration: none;
}
.mega-dropdown-menu .dropdown-header {
  font-size: 18px;
  color: #ff3546;
  padding: 5px 60px 5px 5px;
  line-height: 30px;
}