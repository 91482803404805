#logo {
	position: absolute;
	pointer-events: none;
	z-index: 10;
	margin-top: $carousel_height/2;
	margin-left: 50vw;
	background: url('../images/logo_abf_1.png') no-repeat center center;
	height: 138px;
	width: 320px;
	transform: translate(-50%, -50%);
}

#logo_mobile {
	position: absolute;
	pointer-events: none;
	z-index: 10;
	margin-top: $carousel_height/4;
	margin-left: 50vw;
	background: url('../images/logo_abf_1.png') no-repeat center center;
	background-size: cover;
	height: 69px;
	width: 160px;
	transform: translate(-50%, -50%);
}

.imgmiddle_accueil {
	height: $cssimage_1_height;
	padding: $cssimage_1_margintop;
	padding-left: 0 !important;
	padding-right: 0 !important;
	background: url($url_cssimage_1) no-repeat center center;
    background-size: cover;
}

#form_contact {
	background-color: lighten($color_background, 10%);
	padding: 30px;
	margin-top: 50px;
}

#form_contact h2 {
	color: white;
}

#carte_googlemaps {
	height: $googlemap_height;
}

.confirmation {
	background-color: white;
	margin-top: 30px;
	padding: 20px;
	text-align: center;
}

#confirmation_envoi_mail_ok { display: none }
#confirmation_envoi_mail_fail { display: none }